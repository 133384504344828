@import bootstrap
@import axis

$sky = #669fbd //rgb(102, 159, 189)
$wash-thru = rgba($sky, 15%)
$wash-solid = #e8f1f5 //rgb(232,241,245)
$blue = #005f94 //rgb(0,95,148)
$blue2 = #0c4b6c //rgb(12, 75, 108)
$blue3 = #012d44 //rgb(1, 45, 68)
$navy = #002232 // rgb(0, 34, 50)
$yellow = #e3c820 //rgb(227, 200, 32)
$gray = rgba(#aeb2b4, .4)

$till = 'Titillium Web', sans-serif
$ssp = 'Source Sans Pro', sans-serif

h1,h2,h3,h4,h5,h6
	font-family $till
	text-transform uppercase
	font-weight 700
	
p
	font-family $ssp
	font-size 20px
	font-weight 300

a
	text-decoration none
	
main a
	font-weight 600
	color $blue
	
	
ol, ul
	list-style-type none
	
form
	+html5-inputs()
		input()
	
.container-fluid
	box-sizing content-box
	padding 0 100px
	max-width 1500px
	+below(991px)
		padding 0 50px
	+below(415px)
		padding 0 25px
	
	
transtion(props = all)
	transition props .3s ease
	
bg(uri)
	background-image url('/images/'+uri)
	background-size cover
	background-position center center

button($size = 18, $color = $navy)
	// dif = 56 - $size
	// padding unit(dif/2, 'px') unit(dif, 'px')
	padding 12px 32px
	background $yellow
	border-radius 5px
	color $color
	font-family $till
	font-weight 700
	font-size unit($size, 'px')
	text-decoration none
	text-transform uppercase
	hover-pop()
	transition()
	span
		icon-arrow(right, @font-size, @color, 3px)
		&:after
			margin-bottom calc(1% + 2px)

button-no-hover($size = 18, $color = $navy)
	// dif = 56 - $size
	// padding unit(dif/2, 'px') unit(dif, 'px')
	padding 12px 32px
	background $yellow
	border-radius 5px
	color $color
	font-family $till
	font-weight 700
	font-size unit($size, 'px')
	text-decoration none
	text-transform uppercase
	span
		icon-arrow(right, @font-size, @color, 3px)
		&:after
			margin-bottom 2px
		
plain-link()
	float right
	margin-top 75px
	margin-right 20px
	display inline-block
	color $blue2
	font-family $till
	font-size 24px
	font-weight 700
	text-transform uppercase
	span
		display inline-block
		icon-arrow(right, @font-size, @color, 3px)
		&:after
			margin-bottom 15%
	
	
// 
//	HEADER
// 
header
	group()
	
	.container-fluid
		padding 0 50px
		+below(375px)
			padding 0 25px
		
	// top menu
	#header-top-ribbon
		padding 8px 0
		background-color $blue3
		font-family $ssp
		+below(767px)
			display none
		
		.caps
			text-transform uppercase
			
		ul
			margin 5px 0
			padding-left 0
			float right
			group()
			li
				display inline-block
				+above(769px)
					margin-left 10px
				a
					padding 5px 10px
					color #fff
					font-size 16px
					font-family $till
					font-weight 600
					transition()
					&:hover
						text-decoration none
						background-color lighten($navy, 15%)
						border-radius 5px
						transition()
					.fa-shopping-cart
						color $yellow
				
		#button-member-login
			button()
			padding 5px 25px
			transition()
			&:hover
				background darken($yellow, 20%)
				transition()
	
	
	//bottom menu
	.navbar
		border-radius 0
	#header-bottom
		padding 4px 0 10px
		background-color $sky
		
		.navbar-brand
			padding 0
			width 350px
			img
				max-width 100%
				group()
			+between(1351px, 1450px)
				width 250px
			+between(1076px, 1350px)
				display none
			+between(501px, 1075px)
				width 300px
			+below(500px)
				padding-top 15px
				width 250px
			+below(425px)
				padding-top 20px
				width 175px
		
		// nav-toggle
		button
			.icon-bar
				background #fff
			&:hover
				background rgba(0,0,0,.1)
				
			+below(1075px)
				margin 11px 0 0
			+below(500px)
				margin 20px 0
		
		#main-menu
			border-top 0
			padding-right 0
			.nav
				margin 30px 0 0
				padding-left 0
				float right
				group()
				// +below(1450px)
				// 	margin 30px 0 15px
				+below(1350px)
					margin 15px 0 12px
				
				li
					display inline-block
					margin-left 10px
					a
						padding 5px 10px
						color #fff
						font-family $till
						font-size 16px
						text-transform uppercase
						border-bottom 2px solid transparent
						transition()
						+below(1530px)
							font-size 16px
						+between(1150px, 1350px)
							font-size 18px
							
						&:hover,
						&:active
							text-decoration none
							border-color $yellow
							transition()
							
					+below(1075px)
						width 100%
						position static
					
					// li
					+below(1225px)
						margin-left 0
				
				.open
					a
						background-color transparent
						
					.dropdown-menu
						margin unset
						top 30px
						background $navy
						border-radius 0
						border 0
						border-top 2px solid $yellow
						li
							display block
							margin-left 0
							&:last-of-type
								a
									border-bottom 0

							a
								padding-left 25px
								padding-right 25px
								border-bottom 1px solid rgba(0,0,0,.3)
								transition()
								&:hover
									background $blue2
									transition()
								+below(480px)
									font-size 14px
									

			+below(1075px)
				position absolute
				z-index 2
				top 64px
				right 0
				margin-top 1px
				padding 15px 50px
				width 50vw
				background $navy
				+below(480px)
					padding 15px 0 0
				
				.nav
					margin 0 0 15px 0
					+below(480px)
						margin 0
						// border-bottom 2px solid $sky
					li
						margin-left 0
						a
							padding 10px
							border-bottom 1px solid rgba(#fff, .3)
						&:last-of-type
							a
								border-bottom 2px solid $sky
					
					.open
						border-bottom 0
						a[data-toggle="dropdown"]
							color $yellow
							border-bottom 0
						.dropdown-menu
							position static
							width 100%
							box-shadow unset
							border-top 1px solid $yellow
							border-bottom 1px solid $yellow
							
			+below(768px)
				width 100%
			+below(500px)
				top 88px

				
//			
// HOMEPAGE
// 

.big-box
	width 100%
	text-align center
	
	h1
		font-weight 700
	
	p
		max-width 975px
		margin 25px auto
	
	a.btn
		button()
	
#intro
	padding 100px 0 150px
	bg('background-home1.jpg')

	h1
		font-size 76px
		color #fff
	
	p
		color #fff
		font-family $ssp
		font-size 24px
		
	a.btn
		button(18px)
		span
			icon-arrow(right, @font-size, @color, 3px)
	
	+below(425px)
		padding 25px 0 
		
		h1
			font-size 36px
		p
			font-size 18px
		a.btn
			font-size 18px

#shoparea
	padding 25px 0 100px
	background $gray
	
	h2
		font-size 50px
		color $blue2
	p
		color $blue2
		font-family $ssp
		font-size 24px
		
	.shop-box
		padding 25px 25px 0
		background #fff
		
		h3
			color $blue
			font-size 38px
			text-transform uppercase
			
		p
			color $blue2
			
		a.btn
			button(20px, #fff)
			transform translateY(50%)
			transform-origin center center
			&:hover
				hover-pop-offset(1.1)
				
			span
				icon-arrow(right, @font-size, @color, 3px)
				&:after
					margin-bottom 3px
		// .shop-box
		+between(992px, 1199px)
			img
				width 60%
			p
				font-size 20px
				
		+below(991px)
			margin-bottom 50px
			
	.plain-link
		plain-link()
		
	+below(425px)
		padding 25px 0
		
		.container-fluid
			padding 0 15px
			
		h2
			font-size 36px
		p
			font-size 18px
			max-width 90%
			
		.shop-box
			padding 15px 15px 0
			text-align center
			h3
				font-size 28px
			p
				margin-top 0
				margin-bottom 0
			a.btn
				padding 10px 30px
				font-size 18px
		.plain-link
			margin-top 25px
			font-size 18px
			span:after
				margin-bottom 8%
				
	+below(375px)
		.plain-link
			font-size 16px
	

#recertification
	@extends #intro
	padding-top 50px
	padding-bottom 75px
	
	bg('background-home2.jpg')
	
	h2
		font-size 50px
		color #fff
		
	+below(425px)
		h2
			font-size 36px
	+below(375px)
		h2
			font-size 28px
	
#event-area
	padding 25px 0 125px
	+below(991px)
		padding-bottom 55px
		
	+between(1199px, 1550px)
		.col-lg-3
			margin-bottom 50px
			width 33%
	
	h2
		padding 25px
		color $navy
		font-size 50px
		text-align center
		
	.event-box
		padding 25px 25px 0
		background $blue3
		color #fff
		font-family $ssp
		text-align center
		
		section
			padding 25px
			font-size 20px
			text-align center
			font-size 20px
			.date
				display inline-block
				margin-top 10px
				color $yellow
				font-family $till
				font-size 100px
				line-height .75
		
		p
			overflow hidden

		a.btn
			button(18px, #fff)
			transform translateY(50%)
			&:hover
				hover-pop-offset(1.1)
			
			span
				icon-arrow(right, @font-size, @color, 3px)
				&:after
					margin-bottom 3px
		
		// .event-box
		+below(1199px)
			margin-bottom 75px
			
			&:last-child
				margin-bottom 50px
			
	h4
		color #fff
		font-family $till
		font-size 24px
		font-weight 600
		
	.plain-link
		plain-link()
		+below(991px)
			margin-top 0
	
	+below(425px)
		padding-top 0
		
		.container-fluid 
			padding 0 15px
		
		h2
			margin 10px 0 0
			font-size 36px
		.event-box
			section
				padding 0
				font-size 28px
				.date
					font-size 72px
					
		.plain-link
			margin-top 25px
			font-size 18px
		
	
		
#job-listings
	@extends #recertification
	
	bg('background-home3.jpg')



// 
// Breadcrumb
// 
.breadcrumb-wrapper
	margin-bottom 75px
	background $gray
	// +below(1199px)
		// margin-bottom 0
	+below(776px)
		margin-bottom 50px

	.breadcrumb
		margin-bottom 0
		padding 0
		background transparent
		li
			font-family $till
			text-transform uppercase
			color $blue3
			font-size 18px
			+below(480px)
				font-size 14px
			+below(321px)
				font-size 11px
			&:before
				color $blue3
			a
				display inline-block
				padding 10px 0
				&:hover
					text-decoration none
			.active
				color $sky

// 
// Accordion
// 
.sidebar-nav-accordion
	box-sizing content-box
	padding 0
	height auto
	font-family $till
	text-transform uppercase
	+between(992px, 1199px)
		transform translateX(-75px)
		width calc(100% + 100px)
	li
		position relative
		background $blue3
		border-bottom 1px solid rgba(#fff, .1)
		color #fff
		font-size 20px
		cursor pointer
		&:hover
			background darken($blue3, 10%)
		+between(992px, 1199px)
			font-size 18px

		a
			width 100%
			color #fff
			&:hover
				text-decoration none
			
		.arrow
			icon-arrow(right, @font-size, @color, 3px)
			position absolute
			right 25px
			top 12px
			transition()
			
		.arrow-down
			icon-arrow(down, @font-size, @color, 3px)
			position absolute
			right 25px
			top 12px
			transition()
		
		ul
			padding 0
			li
				padding 0
				border-bottom 0
				a
					display inline-block
					padding 10px 40px
					width 100%
					background $wash-solid
					border-bottom 1px solid rgba(#000, .1)
					color $blue3
					font-size 16px
					transition()
					transition-delay .15s
					&:hover
						background $sky
						color #fff
						transition()
						transition-delay .15s
	
		&:last-of-type
			border-bottom 0
	
	.active
		color $yellow
		.arrow:after,
		.arrow-down:after
			border-color $yellow
	
	.li-pad
		display inline-block
		padding 15px 20px
	
	+between(1200px, 1460px)
		width calc(100% + 75px)

	// sidebar-nav-accordion
	+below(767px)
		margin-top 50px
		padding-right 0


// 
// Banners
// 
.bg-membership
	background-image url('/images/banner/membership.jpg')
.bg-education
	background-image url('/images/banner/education.jpg')
.bg-examination
	background-image url('/images/banner/examination.jpg')
.bg-verify
	background-image url('/images/banner/verify.jpg')
.bg-recertification
	background-image url('/images/banner/recertification.jpg')
.bg-about
	background-image url('/images/banner/about.jpg')


// 
// Text Banner
// 
.text-banner
	padding 100px 0
	background $blue3
	h1
		color #fff
		font-size 76px
		+below(768px)
			font-size 48px
		+below(480px)
			font-size 32px


// 
// Secondary
// 
.page_secondary
	font-family $ssp
	
	.banner
		position relative
		padding 215px 0
		background-color $blue3
		background-position center center
		background-repeat no-repeat
		+below(776px)
			padding 115px 0 !important
		&:before
			content ''
			position absolute
			top 0
			left 0
			height 100%
			width 100%
			background-color rgba($blue3, .9)
			
		h1
			position relative
			font-size 76px
			color #fff
			+below(776px)
				font-size 48px
			+below(480px)
				font-size 32px
	
	main
		margin-bottom 100px
		padding-left 50px
		color $blue2
		+below(1199px)
			padding-left 15px
		+between(992px, 1199px)
			transform translateX(40px)
			
		h2
			font-size 50px
			padding 45px 0 15px 0
			&:first-of-type
				margin-top -55px
			+below(776px)
				font-size 42px
			+below(480px)
				font-size 32px
				padding-bottom 25px
		
		h3
			margin 25px 0 10px
			font-size 24px
			line-height unset !important
			+below(480px)
				font-size 28px
			
		p
			font-size 20px
			margin-bottom 20px
			
		ul
			list-style-type disc
			margin-bottom 20px
			li
				font-size 20px
				font-weight 300
		ol
			list-style-type decimal
			margin-bottom 20px
			li
				font-size 20px
				font-weight 300
	
	aside
		margin-bottom 100px
		+between(1200px, 1460px)
			transform translateX(-50px)


// 
// Membership
// 
#page-membership
	.banner
		padding 218px 0
		bg('banner/examination.jpg')
		+below(767px)
			padding 118px 0

// Membership Application
#page-membership_application
	@extends #page-membership
	// .application-content .container-fluid
		// border 1px solid violet
	
	.membership-notice-message
		position relative
		margin-bottom 50px
		padding 25px
		border 1px solid $yellow
		border-radius 5px
		h2
			+above(1200px)
				width calc(100% - 75px)
		
		.message-close
			icon-x(42px, $blue2, 2px)
			position absolute
			right 50px
			margin -5px
			padding 10px
	
		+below(850px)
			h2
				font-size 36px
			.message-close
				top 15px
				&:before,
				&:after
					width 36px
		+below(675px)
			h2
				font-size 28px
				width calc(100% - 50px)
			.message-close
				top 15px
				right 25px
				&:before,
				&:after
					width 28px
		+below(375px)
			h2
				font-size 20px
			.message-close
				right 10px
			

	.page-tracker-header
		margin-top 0
		padding 50px 0 15px
		+above(1199px)
			padding-top 0
	

	// progress-bar
	.application-progress-bar
		position relative
		z-index 0
		margin 15px 0
		padding 15px
		width 100%
		height 18px
		border-radius 5px
		background $wash-thru
		overflow hidden
		.progress
			position absolute
			top 0
			left 10px
			display flex
			align-items center
			justify-content flex-end
			padding-left 10px
			height 100%
			background $blue2
			overflow visible
			font-family $till
			transform skew(-15deg)
			.percent
				position relative
				top -1px
				margin-right 25px
				color #fff
				font-size 18px
				transform skew(15deg)
		.indicator
			position absolute
			top 0
			left 0
			padding 0 15px
			height 100%
			background $yellow
			border-radius 5px
			.stage
				position relative
				top 2px
				color $blue2
				font-weight bold
				font-size 18px
				.fa-check-circle-o
					font-size 1.4em
					margin-top 1px
	
	// form
	form
		group()
		+below(1199px)
			margin-bottom 50px
			
		section
			margin 0 0 25px 0
			padding 10px 25px 35px
			background $wash-solid
			+below(1199px)
				padding-bottom 25px
				
			.subhead:last-child
				text-decoration underline

			input[type="text"],
			input[type="password"]
				box-sizing content-box
				padding-left 0
				padding-right 0
				width 100%
				color inherit
				text-indent 10px
			select
				width 100%
				padding .45em
				font-size 16px
				border-radius .2em
				border 1px solid #bbb
				box-shadow inset 0 1px 1px rgba(#000, .1)
			input[type="checkbox"]
				width 20px
				height 20px
				
			hr
				border-top 1px solid $blue2
			
			.invis_recaptcha
				margin-top 20px
			
		.sub-head
			font-size 36px
			font-family $ssp
			font-weight 600
		
		label
			margin-top 10px
			font-size 20px
			text-transform uppercase
			
		.radio-group
			margin-top -15px
			label
				label()
				display inline-block !important
				padding-right 25px
				
				// radio() is an Axis mixin
				radio($yellow)
				span:before
					position relative
					top -3px
					left 5px

		.buttons
				
			input[type="submit"]
				button()
				border 0
				padding 10px 20px
				&:hover
					transform unset


		// page 1
		#clinical-non-clinical
			margin-top -15px
			label
				label()
				display inline-block !important
				padding-right 25px
				font-size 18px
				
				//radio() is an Axis mixin
				radio($yellow)
				span:before
					position relative
					top -3px
					left 5px
			
		#credentials-checkboxes
			display flex
			justify-content flex-start
			.check-wrap-row
				display flex
				flex-direction row
				+below(700px)
					flex-direction column
					flex 1
					align-items flex-start
					
			.check-wrap-single
				padding 0 15px
				display flex
				flex-direction column
				align-items center
				
			label
				margin-top 0

			input[type="checkbox"]
				display block
				height 20px
				width 20px
				
				+below(700px)
					width 105px
		
		
		// page 2				
		#email-permission
			margin-top -15px
			label
				label()
				display inline-block !important
				padding-right 25px
				font-size 18px
				
				//radio() is an Axis mixin
				radio($yellow)
				span:before
					position relative
					top -3px
					left 5px
		
		// page 3
		#training-under-physician-checkboxes
			margin-top -15px
			label
				label()
				display inline-block !important
				padding-right 25px
				font-size 18px
				
				// radio() is an Axis mixin
				radio($yellow)
				span:before
					position relative
					top -3px
					left -5px
		
		// page 4
		.form-page-4
			group()
			
			label[for="_f_employment_ncs_length"]
				margin-bottom 20px
			textarea[name="employment_other_ncs"]
				width 100%
				max-width 485px
				height 260px
				margin-bottom 7px
			
			.employment-procedures-other
				display none
			
			textarea[name="employment_procedures_other"]
				width 100%
				max-width 485px
				min-height 120px
		
		// page 5
		#ethics_agreement
			font-size 20px

		input[name="i_agree"]
			margin 4px 10px 0 5px
			width 20px
			height 20px
			position relative
			top 3px
			
		// page 6
		.cost-summary
			th
				font-size 36px
			td
				font-size 36px
				text-indent 25px
				
		.cc-info
			h4
				font-size 28px
				
			hr
				margin 10px 0
				
		// page 7			
		.form-page-7
			group()
			table
				width 100%
				font-size 28px
				color inherit
				tr
					td:first-of-type
						padding-left 10px
					&:nth-child(odd)
						background rgba(#000, .05)
					
			#payment-info-right
				border-left 1px solid
				padding 0 50px
				
				+below(1199px)
					border-left 0
					padding 20px 15px 0
				.address-line
					font-size 28px

	
	footer
		margin-top 100px


// 
// Members
// 
#page-members_login
	.big-box
		padding 100px 0
		
	main
		margin-bottom 100px
	
	#login
		width 100%
		max-width 525px
		margin auto
		padding 50px
		border 1px solid $wash-solid
		color $blue3
		
		.login-message
			margin-bottom 25px
		
		h2
			font-size 36px
			margin-bottom 0
			padding-bottom 18px
		
		fieldset
			max-width 500px
			input
				margin-bottom 25px
				width 100%
				border 0
				border-radius 0
				border-bottom 1px solid
				box-shadow unset
				
			button
				button()
				display block
				margin 5px 0 25px
				padding 8px 75px
				border 0
				&:hover
					transform unset
				
			a
				&:first-of-type
					float left
				&:last-of-type
					float right
					text-align right
			
			.radio-wrapper
				label()
				display linline-block
				
				radio($yellow)

		 
// 
// 	Forgot Password
// 
#page-forgot-password
	main form
		margin auto
		padding 50px
		width 100%
		border 1px solid $wash-solid
		+below(480px)
			padding 50px 15px

		h2
			font-size 36px
			margin-bottom 0
			padding-bottom 18px
			+below(480px)
				font-size 20px
		
		label,
		input
			display block
			width 300px
			max-width 100%
	
		.btn
			button-no-hover()
			margin 25px 0
			padding 8px 75px
			width unset


// 
// Continuing Education
//
#page-events
	.event-box
		margin-bottom 50px
		padding 25px 25px 0
		background $blue3
		color #fff
		font-family $ssp
		text-align center
		
		section
			padding 25px
			font-size 20px
			text-align center
			.date
				color $yellow
				font-family $till
				font-size 100px
				line-height .75
				
		p
			overflow hidden
			max-height 200px
		
		h4
			font-size 24px

		a.btn
			button(18px, #fff)
			transform translateY(50%)
			&:hover
				hover-pop-offset(1.1)
			
			span
				icon-arrow(right, @font-size, @color, 3px)
				&:after
					margin-bottom 3px
		
		// .event-box
		+below(1199px)
			margin-bottom 75px
			
			&:last-child
				margin-bottom 50px		


// 
// Single Event
// 
#page-single_event
	main
		.plain-link
			plain-link()
			margin-top 0
			float none
		
		a.btn
			button-no-hover()


// 
// Event Registration
// 
#page-event_registration
	main
		form
			.buttons
				margin 25px 0
			.btn
				button-no-hover()
				border 0
				
			select
				padding 6px 5px
				font-size 18px
				border-radius 3px
				border 1px solid #bbb
				box-shadow inset 0 1px 1px rgba(#000, .1)
				
			input[type="text"],
			select
				width 100%
				
			label
				label()
				display inline-block
				font-size 18px
				padding-right 25px
				
				radio($yellow)
				span:before
					display inline-block !important
					position relative
					top -3px
					right 5px
					margin-left 8px
			
			.radio-wrapper
				label
					width unset


		.register-page1
			.field
				label
					display inline-block !important
				.input
					display inline-block
					font-size 20px

		.register-page3
			form,
			th,
			td,
			span.label,
			span.amount
				font-size 28px
				
			span.label
				color unset
				
			th,
			span.label
				padding-left 0
				padding-right 15px
			
			textarea
				min-height 175px
				width 350px
				max-width 100%
		
		.register-page4
			section
				font-size 28px
			
				

#page-continuing_education
	#available-papers
		list-style-type none
		padding-left 0


// 
// Glossary
// 
#page-glossary
	.nav-tabs 
		background $sky
		display inline-block
		border 0
		li 
			margin 0
			a
				padding 5px 10px
				margin 0
				border 0
				border-radius 0
				color #fff
				font-size 20px
				transition()
				&:hover
					background $blue2
					color $yellow
					transition()
		
		.disabled
			a,
			a:hover
				background #ecf0f1
				color #ccc
				
	.nav-tabs .active a
		background $blue2
		color $yellow
		
	dl
		border 1px solid #f9f9f9
		dt
			padding 25px 25px 5px
			font-size 20px
			&:nth-of-type(even)
				background $wash-thru
		dd
			padding 0 25px 25px
			font-size 18px
			// border-bottom 1px dotted $sky
			&:nth-of-type(even)
				background $wash-thru



// 
// Eligibility Requirements
// 
#page-eligibility_requirements
	main
		h2
			margin-bottom -35px
		h3
			text-transform none

		em
			font-size 24px
			font-weight 700
			line-height 2
			

// 
// Verify R.NCS.T.
// 
#page-credential_verification
	h2
		padding-bottom 0

	main
		.row
			margin-left 0
			margin-right 0
	
		form
			display inline-block
			padding 40px 50px
			background $wash-thru
			label
				display block
				padding-right 15px
			input
				margin-bottom 10px
				width 100%
				
			select
				padding 7.2px
				width 100%
				border 1px solid #bbb
				border-radius 3px
				box-shadow inset 0 1px 1px rgba(#000, .1)
				
				
			input[type="submit"]
				button()
				padding 12px 32px
				margin-top 25px
				width 250px
				border 0
				font-size 14px
				font-weight bold
				&:hover
					transition none
					transform unset



// 
// Recertification
// 
#page-recertification-ncs-tech-faq
	main strong
		font-size 24px


// 
// Board of Directors
// 
#page-board_of_directors
	main
		.row
			padding 25px 0
			border 1px solid $wash-solid
			border-left 0
			border-right 0
			img
				float left
				width 230px
				height 300px
				padding-right 25px
				padding-bottom 15px
			strong
				font-size 24px
			i
				font-size 18px
			
			p:first-of-type
				padding-top 25px
		
// 
// Commmittees
// 
#page-committees
	main strong
		font-size 24px
		



// 
// Study Materials
// 
#page-study_materials
	@extends .page_secondary
	
	main
		h2
			padding-left 15px
			
		.row
			padding-bottom 50px
		
		.product-box
			margin-bottom 50px
			padding 25px 25px 0
			background $blue3
			color #fff
			font-family $ssp
			text-align center
			
			h3
				line-height 2
				font-weight 700
			
			h4,
			h4 span
				font-size 20px
			
			p
				margin 35px 0 0
			
			a.btn
				button(20px, #fff)
				transform translateY(50%)
				transform-origin center center
				&:hover
					hover-pop-offset(1.1)
					
				span
					icon-arrow(right, @font-size, @color, 3px)
					&:after
						margin-bottom 3px
		
		hr
			margin-bottom 50px
			border 0
			height 1px
			background $gray


// 
// Shop Category
// 
.page-shop_category
	@extends .page_secondary
	@extends #page-study_materials
	
	main .product-box h3
		margin 10px 0 25px
		font-size 26px
		line-height 1.33
		
	a.plain-link
		plain-link()
		&:hover
			text-decoration underline


// 
// Shop Single
// 
.page-shop_single
	@extends .page_secondary
	
	main
		padding-left 50px
		h2
			padding-bottom 25px
			margin-bottom 25px
			text-transform unset
			&:first-of-type
				margin-top -50px
			
				
		.price
			font-size 24px
			line-height 1
			color $blue
	
	label[for="qty"]
		display block
		font-size 24px
		color $blue
		font-weight 400
		
	.product-order
		margin-top 25px
		
	#quantity
		position relative
		display inline-block
		margin 0
		padding 0
		height 35px
		input
			position absolute
			top 0
			left 50px
			width 50px
			height 35px
			margin 0
			padding 0
			border 1px solid #bbb
			border-radius 0
			border-left 0
			border-right 0
			color $blue2
			text-align center
			font-family $ssp
			font-size 2em
			-moz-appearance textfield
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button
				-webkit-appearance none
		div
			position absolute
			top 0
			left 0
			background $yellow
			width 50px
			height 35px
			&:nth-of-type(2)
				left 100px
			i
				display inline-block
				position absolute
				top 0
				right 0
				bottom 0
				left 0
				margin auto
				height 20px
				width 20px
				transform translateY(-10%)
	
	button
		button(18, $blue2)
		border 0
		margin-top 25px
		
	.go-back
		plain-link()
		float none
		margin-top 25px
		
		i
			display inline-block
			margin-right 10px
			position relative
			top -3px
			icon-arrow(left, @font-size, @color, 3px)


// 
// Shopping Cart
// 
#page-shopping_cart
	@extends .page_secondary
	
	.alert
		padding-top 10px
		padding-bottom 10px
		position absolute
		top -43px
		left 15px
		border-radius 0
		width calc(100% - 30px)
		
	main
		padding 0 15px
		transform none !important
		// important used because it is inheriting
		// an undesired translateX 50px from 
		// @extends .page_secondary
			

	.cart-wrapper
		group()
		padding 50px
		background $wash-thru
		+below(700px)
			padding 50px 15px
			margin-left -25px
			margin-right -25px
		+below(415px)
			.col-sm-12
				padding-left 0
				padding-right 0
		
		a.btn
			button-no-hover()
			text-transform none
			padding 8px 24px
	
	table
		.quantity 
			input
				width 75px
		
		.update-row
			border 0
			margin 25px
			
		.update-wrapper
			padding-right 0
			
		.update-cart.btn
			float right
			margin 25px 0
			background $yellow
			color $blue3
		
		.line-description
			padding 0 25px 0 0
			font-size 28px
			font-weight 600
			
		.price-total
			padding 0
			font-size 28px
	
	#shopping-cart-button-row
		group()
		display block
		margin-top 25px
		
	#promo-form
		display inline-block
		position relative
		
		label
			position absolute
			left 0
			top -25px
			font-size 16px
		
		input[name="code"]
			padding-top 8px
			padding-bottom 8px
			width 100px
			margin-right 10px
			font-size 18px

	input.btn
		button-no-hover()
		padding .45em 24px
		font-size 16px
		text-transform none
		
	#buttons
		float right
		+below(700px)
			float none
			width 100%
			margin-top 25px
			text-align right
		+below(400px)
			a.btn
				display block
				margin-bottom 10px

	+below(415px)
		.breadcrumb-wrapper
			margin-bottom 0
		.membership-callout
			margin-top 0
			

// 
// Checkout
// 
#page-shopping_cart_checkout
	@extends #page-shopping_cart
	

// 
// Product
// 
#page-product
	@extends .page_secondary
	
	.text-banner
		padding 100px 0
		background $blue3
		h1
			color #fff
			font-size 76px
	
	h3
		color $sky
	
	.credit-price
		margin 50px 0
		h3
			line-height .75
	
	.quantity
		margin-bottom 25px
		h3
			display inline-block
		input[type="number"]
			margin-left 15px
			box-sizing content-box
			padding-left 15px
			width 50px
			border 2px solid $yellow
			border-radius 3px
			line-height 2
			font-size 24px
	
	a.btn
		button()
		padding 15px 45px
		
	.plain-link
		plain-link()
		float none
		margin-top 50px
		span
			display inline-block
			icon-arrow(left, @font-size, @color, 3px)
			&:after
				margin-bottom 15%


// 
// Membership Callout
// 
.membership-callout
	@extends #recertification
	bg('background-membership_callout.jpg')



// 
// Members Area
// 
.members-area
	footer
		margin-top 50px


// 
// Edit Profile
// 
#page-members_edit_profile
	main
		.nav-tabs .active
			font-weight 600
			color $blue2 !important
			
		.tab-content
			padding 0 50px 50px
			
		h2
			margin 25px 0 0
			padding 0
		
		.sub-head
			margin 25px 0 15px 15px
			font-size 32px
			
		form
			input
				width 100%
				margin-bottom 10px
				
			select
				margin-bottom 10px
				padding 8px
				width 100%
				border 1px solid #bbb
				border-radius 3px
				box-shadow inset 0 1px 1px rgba(#000, .1)
				
			input.btn
				button-no-hover()
				width unset
				border 0
			
			
			label
				label()
				display inline-block !important
				padding-right 25px
				font-size 18px
				
				//radio() is an Axis mixin
				radio($yellow)
				span:before
					position relative
					top -3px
					left 5px
				
			.checkboxes
				
				label
					font-size 20px
					font-weight 400

				input
					position relative
					top 4px
					margin-right 8px
					height 20px
					width 20px
					
			textarea
				width 100%
				min-height 200px


// 
// CE Credits
// 
#page-members_ce_credits
	textarea
		width 100%
		max-width 500px
		min-height 200px
	
	.buttons input
		button-no-hover()
		border 0
		margin 25px 0
		

// 
// Renew
// 
#page-members_renew
	@extends #page-membership_application
	
	.renew-page-1
		input[type="submit"]
			margin 25px 0
	
	.renew-page-2
		tr th,
		tr td
			font-size 28px

		.renew-radios
			label
				label()
				display inline-block !important
				padding-right 25px
				font-size 18px
				
				radio($yellow)
				span:before
					position relative
					top -3px
					left 5px
		
		.disclaimer
			margin 15px 0 25px
			
		.visible-card-only
			display none
			

// 
// Page 404
// 
#page-404
	background-color $blue3
	h1
		color $yellow
	main
		h2
			color #fff
			text-align center
		p
			margin auto
			width 800px
			max-width 100%
			color #fff
		strong
			color $yellow				


// 
// Footer
// 
footer
	padding-bottom 25px
	background-color $navy
	color #fff
	font-family $ssp
	overflow hidden
	
	.container-fluid
		position relative
		.row
			margin-left 0
			margin-right 0
	
	#sign-up-ribbon
		position relative
		display block
		padding 25px 0
		group()
			
		> div
			strong
				display inline-block
				margin-right 25px
				font-size 20px
				font-family $ssp
				font-weight 700
				text-transform uppercase
			form
				margin-bottom 0
				
				div
					display inline-block
					+below(650px)
						display block
				
			input
				height 41px
				margin-right 10px
				margin-bottom 0 !important
				+below(760px)
					margin-top 15px
				
			#sign-up-button
				display inline-block
				padding 8px 24px
				background $yellow
				border 0
				border-radius 5px
				color $navy
				font-family $till
				font-size 18px
				text-transform uppercase
				transition()
				span
					display inline-block
					position relative
					bottom 3px
					left 8px
					icon-arrow(right, @font-size, @color, 2px)
				&:hover
					background-color darken($yellow, 20%)
					text-decoration none
				+below(760px)
					margin-top 25px
					
	hr
		border-top 1px solid rgba(#fff, .1)
		width 100%
		margin 10px 0 35px
		
	.fb
		position absolute
		top 0
		right 100px
		color #fff
		transition()
			
		+below(768px)
			right 50px
			
		+below(480px)
			display inline-block
			margin 5px 0 25px
			position static
			right unset
			
	address
		font-size 20px
		a
			color $sky
			
	#copyright
		display inline-block
		font-size 16px
		a
			color #fff
	
	#footer-links
		float right
		display inline-block
		.footer-page-link
			padding-left 25px
			color #fff
			font-family $ssp
			font-size 16px
	
	+below(776px)
		#sign-up-ribbon
			position relative
			padding 35px 0 50px
			text-align center
			
			div
				float none
				strong
					width 100%
					margin 0 0 15px
				a
					position unset
			a
				position absolute
				bottom 30px
				left 0
				
		#footer-links
			float none
			.footer-page-link
				padding-left 0
				padding-right 20px


.contact
	@extends #page-membership_application
	
	textarea
		width w = 100%
		min-height 200px
		+above(991px)
			width calc(50% - 15px)

	.buttons
		margin-top 25px
		
	footer
		margin-top 0


// 
// Job Postings
// 
#page-job_postings
	#job-feed
		section
			margin-left 0
			margin-right 0
			&:nth-child(odd)
				padding 10px 10px 25px
				background $wash-thru
			
			h1,h2
				text-transform unset
			a
				button-no-hover()
				border 0
				padding 10px 25px
				font-size 16px
				
	a.btn
		button()
		margin 50px 0
		
	.job-page-1,
	.job-page-2,
	.job-page-3,
	.job-page-4
		label
			color $blue3
			font-size 16px
			
		input[type="text"],
		select
			width 100%
			margin-bottom 15px
		
		select
			padding .45em
			font-size 16px
			border-radius 3px
			border 1px solid #bbb
			box-shadow inset 0 1px 1px rgba(#000, .1)
			
		.validation
			display inline-block

	.job-page-2
		textarea
			width 100%
			min-height 350px
				
		.calendar
			position relative
			input[type="text"]
				text-indent 35px
			.calendar-icon
				position absolute
				top 2px
				left 5px
				font-size 24px
	
	.job-page-3
		.btn-group
			label.btn
				background $yellow
				transition()
				&:hover
					background darken($yellow, 20%)
					transition()
			
			label.btn.active
				background darken($yellow, 30%)
			
	.buttons input[type="submit"]
		button-no-hover()
		border 0
		margin 25px 0
	
	.job-page-price-option
			background $blue3
			margin 30px 0
			color #fff
			text-align center
			padding 10px 0
			& > h3
				display table
				width 100%
				margin 0 0 10px
				& > span
					display table-cell
					vertical-align middle
					font-size 18px
			.price-display
				color $yellow
				font-size 16px
	
	+below(480px)
		.job-page-price-option
			margin 10px 0

// 
// Blog
// 
#page-blog
	.row
		margin 0
		
	main
		margin-bottom 100px
		padding-left 0
		padding-right 30px
		
	aside
		margin-bottom 100px
		background $blue3
		padding 0
		
		h2
			margin 0
			padding 15px 0 15px 25px
			color #fff
			
		ul
			margin 0
			padding-left 0
			
			li
				padding 10px 10px 10px 50px
				background $wash-solid
				color $blue3
				font-family $till
				font-size 18px
				a:hover
					text-decoration none
					
#page-privacy
	i
		font-weight 600
		