// --
// UI
// --

// Mixin: Area
//
// Just a nice little area for you to put content in. Rounded corners, box with
// light grey background by default. You can pass it a different color if you'd
// like.
//
// ex. area()
// ex. area: red // <-- not recommended

area($color = #F5F5F5)
  box-shadow: inset 0 0 6px rgba(#000,.05)
  background-color: $color
  padding: 15px
  border-radius: 4px
  margin: 20px 0

// Mixin: Notice
//
// A notice for you. Best used for flash alerts, this was originally an internal
// mixin, but you can use it if you'd like. Give it a color and a width and it
// will output a nice lightly gradiented box for your notice to reside in.
//
// ex. notice()
// ex. notice: 500px blue

notice($width = false, $color = #EEEEEE, $padding = 10px)
  group()
  gradient($color, 3%)
  border-radius: 5px
  padding: $padding
  border: 1px solid darken($color, 10%)
  box-shadow: 1px 1px 3px rgba(#000,.05)
  position: relative
  width: $width if $width

// Mixin: Flash
//
// This is really great for rails flash messages, or really any little
// notifications like 'logged in!', 'logged out!', or 'errarrrr!'. Takes one of
// four types and colors them nicely for you - notice, success, warning, or
// error. Defaults to notice.
//
// ex. flash()
// ex. flash: 'error'

flash($type = 'notice')
  if $type == 'notice'
    notice($color: #6CC5FA)
    color: #fff
    font-weight: bold
    text-shadow: 1px 1px 1px rgba(#000,.2)
  if $type == 'success'
    notice($color: #7BDEA3)
    color: #fff
    font-weight: bold
    text-shadow: 1px 1px 1px rgba(#000,.2)
  if $type == 'warning'
    notice($color: #FAE677)
    font-weight: bold
    text-shadow: 1px 1px 1px rgba(#fff,.2)
  if $type == 'error'
    notice($color: #E77C70)
    color: #fff
    font-weight: bold
    text-shadow: 1px 1px 1px rgba(#000,.2)

// Mixin: Breadcrumb
//
// Lay this one down on a list and it will turn the list into a breadcrumb-y
// thing. By default separates with a slash, but you can feed it any character.
// Second param is spacing between the list items, and the third is the color of
// the divider. All are optional.
//
// ex. breadcrumb()
// ex. breadcrumb: '>'
// ex. breadcrumb: "-" 25px red
// ex. breadcrumb "~" 40px

breadcrumb($character = "/", $spacing = 10px, $divider-color = #CDCDCD)
  inline-list: $spacing

  li:not(:last-child):after
    content: $character
    margin-left: $spacing
    color: $divider-color

// Mixin: Bubble
//
// If you're anything like me, you always seem to find your designers putting
// little bubbles in things. This mixin takes care of that problem, and makes a
// nice bubble with 100% css. All parameters optional - accepts width, pointer
// position (left, center, or right), and a color.
//
// ex. bubble()
// ex. bubble: top center #57777E
// ex. bubble: bottom right #5740A1 500px
// ex. bubble: right top #5740A1 500px

bubble($pos0 = left, $pos1 = false, $color = #EEEEEE, $width = false, $triangle-size = 10px, $side-distance = 15px, $padding = 10px, pointer = false, $triangle-color = darken($color, 3%), $triangle-border-color = darken($triangle-color, 10%))

  // use named argument 'pointer' as $pos0 arg (to preserve old bubble api)
  $border-width = 1px
  $sqrt2 = 1.3
  $distance-y-shadow = ($side-distance - $border-width)
  $distance-x-shadow = ($side-distance - $border-width)
  $margin-left-shadow = auto
  $margin-left = auto
  $margin-top-shadow = auto
  $margin-top = auto
  if pointer
    $pos1 = pointer
    $pos0 = bottom
  // if no $pos1 is given, shift args and assume 'bottom $pos0'
  if $pos1 is false
    $pos1 = $pos0
    $pos0 = bottom
  else if $pos1 is a 'color'
    $width = $color
    $color = $pos1
    $pos1 = $pos0
    $pos0 = bottom

  notice: $width $color $padding

  if $pos0 is top
    $direction-triangle = up
    $position-y = top
    $distance-y = -($triangle-size)
    $distance-y-shadow = -($triangle-size + $border-width * $sqrt2)
  else if $pos0 is bottom
    $direction-triangle = down
    $position-y = bottom
    $distance-y = -($triangle-size)
    $distance-y-shadow = -($triangle-size + $border-width * $sqrt2)
  else if $pos0 is left
    $direction-triangle = left
    $position-x = left
    $distance-x = -($triangle-size)
    $distance-x-shadow = -($triangle-size + $border-width * $sqrt2)
  else if $pos0 right
    $direction-triangle = right
    $position-x = right
    $distance-x = -($triangle-size)
    $distance-x-shadow = -($triangle-size + $border-width * $sqrt2)

  if $pos1 is left
    $position-x = left
    $distance-x = $side-distance
  else if $pos1 is right
    $position-x = right
    $distance-x = $side-distance
  else if $pos1 is bottom
    $position-y = bottom
    $distance-y = $side-distance
  else if $pos1 is top
    $position-y = top
    $distance-y = $side-distance
  else if $pos1 is center
    if $pos0 is top or $pos0 is bottom or $pos1 is false
      $margin-left = -($triangle-size)
      $margin-left-shadow = -($triangle-size + $border-width * $sqrt2 / 2)
      $position-x = left
      $distance-x = 50%
      $distance-x-shadow = 50%
    else
      $margin-top = -($triangle-size/2)
      $margin-top-shadow = -($triangle-size/2 + $border-width * $sqrt2 / 2)
      $position-y = top
      $distance-y = 50%
      $distance-y-shadow = 50%

  &:before
    margin-top: $margin-top-shadow
    margin-left: $margin-left-shadow
    triangle: $direction-triangle ($triangle-size + $border-width * $sqrt2) $triangle-border-color
    absolute: $position-y $distance-y-shadow $position-x $distance-x-shadow
    display: block

  &:after
    margin-top: $margin-top
    margin-left: $margin-left
    triangle: $direction-triangle $triangle-size $triangle-color
    absolute: $position-y $distance-y $position-x $distance-x
    display: block

// Mixin: Figure
//
// For when you want to *semantically* show an image. Put this on your
// <figure /> element and it will style the nested image and figcaption in a
// wonderfully pleasing manner. Takes a color (for the thin outline), and if
// you want, dimensions of the image as a space separated list.
//
// ex. figure()
// ex. figure: blue
// ex. figure(green, 100px 400px)

figure($color = $default-color, $dimensions = false)

  if length($dimensions) > 1
    display: block
    size: $dimensions[0] $dimensions[1]
  else if $dimensions == false
    display: inline-block
  else
    warn("Make sure you pass the image's dimensions as a space-separated list")

  img
    display: block
    padding: 4px
    border-radius: 3px
    border: 1px solid rgba($color, .6)

  figcaption
    padding: 10px 0
    text-align: center
    italic()

// Mixin: icon-arrow
//
// Here's an asset you've probably had to create a lot. Now you don't.
// Creates a nice arrow icon in pure css. Useful for navigation. Takes size,
// color, or stroke.

// ex: close()
// ex: close(25px, yellowgreen, 2px)

icon-arrow($direction = 'right', $size = 40px, $color = #888, $stroke = 1px)
  height: $size
  width: $size

  &:after
    content: ''
    border-right: $stroke solid $color
    border-top: $stroke solid $color
    display: inline-block
    height: unit($size * 0.625, 'px')
    width: unit($size * 0.625, 'px')
    margin-left: 2%
    if $direction == 'right'
      transform: rotate(45deg) translateX(unit($size * 0.1, 'px')) translateY(unit($size * 0.1, 'px'))
    else if $direction == 'left'
      transform: rotate(-135deg) translateX(unit($size * -0.3, 'px') ) translateY(unit($size * 0.1, 'px'))
    else if $direction == 'down'
      transform: rotate(135deg) translateX(unit($size * -0.1, 'px')) translateY(unit($size * -0.1, 'px'))
    else if $direction == 'up'
      transform: rotate(-45deg) translateX(unit($size * -0.1, 'px')) translateY(unit($size * 0.3, 'px'))

// Mixin: icon-x
//
// Here's another n asset you've probably had to create a lot. Now you don't.
// Creates a nice "X" close/delete icon. Takes size, color, or stroke

// ex: close()
// ex: close(25px, yellowgreen, 2px)

icon-x($size = 40px, $color = #888, $stroke = 1px)
  width: $size
  height: $size
  display: block
  position: relative

  &:before, &:after
    transition: all .3s ease
    content: ''
    position: absolute
    width: $size
    top: 50%
    height: $stroke
    background: $color
    transform: rotate(45deg)

  &:after
    transform: rotate(-45deg)

// Mixin: Navigation
//
// Makes a fantastic nav bar for you that doesn't look exactly like bootstrap's
// and you can customize it as well. Params: fixed (boolean), color (recommend
// something dark), link color, and size, which is an integer from one up, and
// scales every aspect of the nav bar for you. All have defaults. This mixin is
// meant to be placed on a <nav> element that contains a ul.
//
// ex. nav()
// ex. nav: 1 #444 red true

navigation($size = 1, $bg-color = #222, $link-color = #fff, $pos-fixed = false)
  group()
  display: block
  z-index: 10
  gradient: $bg-color 5%
  box-shadow: 0 3px 3px rgba(0,0,0,.15)
  border-bottom: 1px solid rgba(darken($bg-color, 15%),.7)
  height: ($size + 14) * 2.866px
  width: 100%
  font-size: unit($size + 14, 'px')

  if $pos-fixed
    position: fixed
  else
    position: relative

  h1
    float: left
    padding: ($size + 14) * .58px
    padding-left: 17px
    color: $link-color
    bold()
    font-size: ($size + 14) * 1.3px

  ul
    float: right
    margin: 0
    padding: ($size + 14) * .8px
    color: $link-color

    li
      list-style-type: none
      margin-right: 30px
      float: left

      a
        color: $link-color
        border: none
        cursor: pointer

        &:hover
          color: darken($link-color, 20%)
          border: none

// Alias: nav
nav = navigation

// Additive Mixin: Flash Notices
//
// WARNING: Creates classes in your css and styles them - not to be used inside
// an element.
//
// Super convenient flash notices. Anything with .flash on it will display as a
// notice, and if you add the additional class .error, .warning, .notice, or
// .success, it will display the appropriate color background for the message.
//
// ex. flash-notices()

flash-notices()
  .flash
    &.error
      flash(error)
    &.warning
      flash(warning)
    &.notice
      flash(notice)
    &.success
      flash(success)
