// List Groups
list-group-item-variant($state, $background, $color)
  .list-group-item-{$state}
    color $color
    background-color $background

    a&,
    button&
      color $color

      .list-group-item-heading
        color inherit

      &:hover,
      &:focus
        color $color
        background-color darken($background, 5)

      &.active,
      &.active:hover,
      &.active:focus
        color #fff
        background-color $color
        border-color $color
